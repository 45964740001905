import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["search", "category"]
  
  connect(){
  }

  search(ev){
    var query = "",
      category = "",
      sub_category = "",
      url = "/products?"

    if (ev.target.classList.contains('global-search')) {
      if (ev.keyCode != 13){
        return true
      }
      query = ev.target.value
      category = $('input[name=category]:checked').val()
      sub_category = $('input[name=sub-category]:checked').val()
    }else{
      query = $(".global-search").val()
      category = ev.currentTarget.dataset.category
      sub_category = ev.currentTarget.dataset.sub_category
    }

    if (query){
      url += "query=" + query
    }

    if (category){
      url += "&category=" + category
    }

    if (sub_category){
      url += "&sub_category=" + sub_category
    }

    window.location.replace(url)
  }
}