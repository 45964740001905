document.addEventListener("turbo:load", function () {
  $("#product-banner-slider").slick({
    infintie: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "7%",
    arrows: false,
    dots: true,
    focusOnSelect: true,
  });

  $("#product-bundle-slider").slick({
    infintie: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    arrows: false,
    dots: true,
    focusOnSelect: true,
  });
})

$(document).on("click", "#bs-prev", function () {
  $("#product-banner-slider").slick("slickPrev");
});

$(document).on("click", "#bs-next", function () {
  $("#product-banner-slider").slick("slickNext");
});
