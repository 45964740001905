import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  resendOtp(e) {
    e.preventDefault();
    Rails.ajax({
      type: "get",
      url: "/resend_otp",
      success: function (response) {
        Toastify({
          text: response.message,
          duration: 5000,
          close: true,
          gravity: "top",
          position: "right",
          className: "toastify-success",
          stopOnFocus: true,
        }).showToast();
      },
      error: function (response) {
        Toastify({
          text: "Something went wrong",
          duration: 5000,
          close: true,
          gravity: "top",
          position: "right",
          className: "toastify-danger",
          stopOnFocus: true,
        }).showToast();
      },
    });
  }

  resendEmailOtp(e) {
    e.preventDefault();
    Rails.ajax({
      type: "get",
      url: "/resend_email_otp",
      success: function (response) {
        Toastify({
          text: response.message,
          duration: 5000,
          close: true,
          gravity: "top",
          position: "right",
          className: "toastify-success",
          stopOnFocus: true,
        }).showToast();
      },
      error: function (response) {
        Toastify({
          text: "Something went wrong",
          duration: 5000,
          close: true,
          gravity: "top",
          position: "right",
          className: "toastify-danger",
          stopOnFocus: true,
        }).showToast();
      },
    });
  }
}



