"use strict";
import gsap from "gsap";
import { Draggable, TweenLite } from "gsap/all";
gsap.registerPlugin(Draggable, TweenLite);

const bannerSlider = () => {
  if ($("#before-after-slider").length > 0) {
    // Drag & Drop Before - After
    var windowWidth = $(window).width(),
      haft_win = windowWidth / 2;
    $(".wrapper-after").width(windowWidth);
    $(window).resize(function () {
      var windowWidth = $(window).width();
      $(".wrapper-after").css("width", windowWidth + "px");
    });

    /*
    Dependencies : TweenMax and Draggable
    Test on touch device @ http://cloud.bassta.bg/before-after.html
    */
    var $dragMe = $("#dragme");
    var $beforeAfter = $("#before-after-slider");
    var $viewAfter = $(".view-after");
    if ($("#dragme").length == 0) return;
    Draggable.create($dragMe, {
      type: "left",
      bounds: $beforeAfter,
      onDrag: updateImages,
    });
    //Intro Animation
    animateTo(haft_win);
    $(window).resize(function () {
      var windowWidth = $(window).width(),
        haft_win = windowWidth / 2;
      animateTo(haft_win);
    });
    function updateImages() {
      var logo = $("#home-navbar .navbar-brand").offset().left,
        drag = $("#dragme").offset().left;
      if (drag < logo)
        $("#home-navbar .navbar-brand").addClass("navbar-brand-dark");
      else $("#home-navbar .navbar-brand").removeClass("navbar-brand-dark");
      TweenLite.set($viewAfter, { width: $dragMe.css("left") }); //or this.x if only dragging
    }

    function animateTo(_left) {
      TweenLite.to($dragMe, 1, { left: _left, onUpdate: updateImages });
    }

    $beforeAfter.on("click", function (event) {
      if (
        event.target.getAttribute("href") !== null &&
        event.target.getAttribute("href") !== undefined
      ) {
        return;
      }
      var eventLeft = event.clientX - $beforeAfter.offset().left;
      animateTo(eventLeft);
    });
  }
};

export default bannerSlider;
