// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../stylesheets/application";
require.context("../images", true);
import "bootstrap";
import "jquery-validation/dist/jquery.validate";
import "./material-input";
import bannerSlider from "./banner_slider";
import "./home";
import "slick-carousel";
import "./products_list";
// import "./countrystatecity";
import "controllers";
import Toastify from "toastify-js";
import "../select2/select2.min";
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsExporting from 'highcharts/modules/exporting';
window.Highcharts = Highcharts;

var jQuery = require('jquery')
Rails.start();
ActiveStorage.start();
//set jquery globally
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

global.Toastify = Toastify;
require('data-confirm-modal')



document.addEventListener("turbo:load", function () {
  $(function () {
    $('.select-2').select2();
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    /**
     * Material Inputs
     */
    $("body").materializeInputs();
  });
  // Intit home page banner slider
  bannerSlider({
    $el: document.getElementById("before-after-slider"),
    defaultValue: 50,
  });
});

// var isOn = true;
// $(function(){
//    $("#updateit").keydown(function(){
//       console.log("isOn", isOn);
//       if ( isOn ){
//           isOn = false;
//           $('.barbanner').css("width", '100px');
//       } else {
//           isOn = true;
//           $('.barbanner').css("width", '200px');
//       }
//       return false;
//   });
// });

// $(document).ready(function(){
//     $(".barbanner-left").click(function(){
//         $(".barbanner-right").animate({
//             width: e.clientX
//         });
//     });
// });

var isResizing = false,
  lastDownX = 0;

$(function () {
  // var container = $(".nb-top-block"),
  //   banner = $(".nb2-home-banner"),
  //   handle = $("#updateit");

  // handle.on("mousedown", function (e) {
  //   isResizing = true;
  //   lastDownX = e.clientX;
  // });

  // $(document)
  //   .on("mousemove", function (e) {
  //     // we don't want to do anything if we aren't resizing.
  //     if (!isResizing) return;

  //     var offsetWidth =
  //       ontainer.width() - (e.clientX - container.offset().left);

  //     banner.css("width", offsetWidth);
  //   })
  //   .on("mouseup", function (e) {
  //     // stop resizing
  //     isResizing = false;
  //   });

  // container.on("click", function (e) {
  //   isResizing = true;
  //   lastDownX = e.clientX;
  // });

  // $(document)
  //   .on("click", function (e) {
  //     // we don't want to do anything if we aren't resizing.
  //     if (!isResizing) return;
  //     console.log("e.clientX", e.clientX);
  //     var offsetWidth = e.clientX;
  //     var offsetRight = e.clientX;

  //     banner.css("width", e.clientX);
  //     handle.css("left", e.clientX);
  //   })
  //   .on("click", function (e) {
  //     // stop resizing
  //     isResizing = false;
  //   });

  //    Supplier handling
  function supplier_login() {
    $("#signin-modal").modal({
      backdrop: "static",
      keyboard: false,
    });
  }

  function supplier_forgot_password() {
    $("#forgot-modal").modal({
      backdrop: "static",
      keyboard: false,
    });
  }
});
$(document).ready(function(){
  $(".select2").select2();
})
require("./nested-forms/addFields");
require("./nested-forms/removeFields");
